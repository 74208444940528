export default [
  {
    items: ["Całe nogi", "Bikini", "Pachy"],
    price: "650",
    saving: "Oszczędzasz 200"
  },
  {
    items: ["Łydki", "Bikini", "Pachy"],
    price: "500",
    saving: "Oszczędzasz 150"
  },
  {
    items: ["Bikini", "Pachy"],
    price: "350",
    saving: "Oszczędzasz 100"
  },
  {
    items: ["Łydki", "Bikini"],
    price: "350",
    saving: "Oszczędzasz 100"
  },
  {
    items: ["Łydki", "Pachy"],
    price: "300",
    saving: "Oszczędzasz 100"
  },
  {
    items: ["Całe nogi", "Pachy"],
    price: "500",
    saving: "Oszczędzasz 100"
  },
  {
    items: ["Całe nogi", "Bikini"],
    price: "550",
    saving: "Oszczędzasz 100"
  },
  {
    items: [],
    price: "4000",
    description: {
      title: "Pakiet All Inclusive",
      text:
        "Seria depilacji całego ciała, ważny przez 12 miesięcy od daty pierwszego zabiegu"
    }
  },
  {
    items: [],
    price: "2000",
    description: {
      title: "Pakiet Exclusive",
      text:
        "Seria depilacji trzech wybranych partii ciała, ważny przez 12 miesięcy od daty pierwszego zabiegu"
    }
  }
];
