export default {
  left: [
    {
      name: "Obszar między brwiami",
      price: "50"
    },
    {
      name: "Baki",
      price: "150"
    },
    {
      name: "Uszy",
      price: "100"
    },
    {
      name: "Wąsik",
      price: "100"
    },
    {
      name: "Policzki",
      price: "150"
    },
    {
      name: "Broda (wąsik gratis)",
      price: "150"
    },
    {
      name: "Cała twarz (policzki, broda, wąsik, baki)",
      price: "200"
    },
    {
      name: "Całe ręce (dłonie, ramiona, przedramiona)",
      price: "200"
    },
    {
      name: "Pachy",
      price: "200"
    }
  ],
  right: [
    {
      name: "Kark",
      price: "150"
    },
    {
      name: "Tors",
      price: "200-300"
    },
    {
      name: "Plecy",
      price: "300"
    },
    {
      name: "Pośladki",
      price: "200"
    },
    {
      name: "Pachwiny",
      price: "180"
    },
    {
      name: "Bikini",
      price: "250"
    },
    {
      name: "Uda (kolana gratis)",
      price: "200"
    },
    {
      name: "Łydki (stopy gratis)",
      price: "200"
    },
    {
      name: "Całe nogi",
      price: "400"
    },
    {
      name: "Brzuch",
      price: "150-250"
    }
  ]
};
