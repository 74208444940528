export default {
  left: [
    {
      title: "O Nas",
      link: "/#o-nas"
    },
    {
      title: "O Depilacji",
      link: null,
      submenu: [
        {
          title: "Laser Light Sheer Desire",
          link: "/o-depilacji/laser-light-sheer-desire"
        },
        {
          title: "Istota działania lasera do depilacji",
          link: "/o-depilacji/istota-dzialania-lasera-do-depilacji"
        },
        {
          title: "Dla kogo depilacja laserowa?",
          link: "/o-depilacji/dla-kogo-depilacja-laserowa"
        },
        {
          title: "Problem nadmiernego owłosienia",
          link: "/o-depilacji/problem-nadmiernego-owlosienia"
        },
        {
          title: "Fazy wzrostu włosa",
          link: "/o-depilacji/fazy-wzrostu-wlosa"
        },
        {
          title: "Budowa włosa",
          link: "/o-depilacji/budowa-wlosa"
        },
        {
          title: "Jak przygotować się do konsultacji i próby laserowej?",
          link: "/o-depilacji/jak-przygotowac-sie-do-konsultacji-laserowej"
        },
        {
          title: "Jak postępować po zabiegu?",
          link: "/o-depilacji/jak-postepowac-po-zabiegu"
        },
        {
          title: "Przeciwskazania",
          link: "/o-depilacji/przeciwskazania"
        }
      ]
    },
    {
      title: "Cennik",
      link: "/#cennik"
    },
    {
      title: "Promocje",
      link: "/#promocje"
    },
    {
      title: "Częste Pytania ",
      link: "/czeste-pytania"
    },
    {
      title: "Wynajem Lasera",
      link: null,
      submenu: [
        {
          title: "Wynajem. Dlaczego my?",
          link: "/wynajem-lasera/dlaczego-my"
        },
        {
          title: "Wynajem urządzenia Light Sheer Desire",
          link: "/wynajem-lasera/wynajem-urzadzenia-light-sheer-desire"
        },
        {
          title: "Wynajem sprzętu z obsługą w Twoim salonie",
          link: "/wynajem-lasera/wynajem-w-twoim-salonie"
        }
      ]
    },
    {
      title: "Galeria",
      link: "/galeria"
    },
    {
      title: "Kontakt",
      link: "/#kontakt"
    }
  ],
  right: [
    {
      icon: "instagram",
      link: "https://www.instagram.com/laserstudio.bedzin/"
    },
    {
      icon: "facebook",
      link: "https://www.facebook.com/LaserStudioBedzin"
    }
  ]
};
