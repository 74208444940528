import "./Promotions.less";
import React from "react";
import promotionsList from "./promotionsList";

const CURRENCY = "zł";

const Promotions = () => (
  <div id="promocje" className="ls-promotions">
    <h1 className="text-primary">Pakiety promocyjne</h1>
    <p>
      Skorzystaj z depilacji kilku obszarów zabiegowych podczas jednej wizyty i
      oszczędzaj!
    </p>
    <div className="promotions-card-wrapper">
      {promotionsList.map(({ items, price, saving, description }, i) => {
        return (
          <div className="promotion-card" key={i}>
            {items.map((item, i) => (
              <div
                key={item + i}
                className="promotion-item"
                style={{ flex: i === items.length - 1 ? 1 : 0 }}
              >
                {item}
              </div>
            ))}
            {description && (
              <React.Fragment>
                <div className="promotion-description-title">
                  {description.title}
                </div>
                <div className="promotion-description-text">
                  {description.text}
                </div>
              </React.Fragment>
            )}
            {price && (
              <div className="promotion-price">
                {price} {CURRENCY}
              </div>
            )}
            {saving && (
              <div className="promotion-saving">
                {saving} {CURRENCY}
              </div>
            )}
          </div>
        );
      })}
    </div>
  </div>
);

export default Promotions;
