import "./Main.less";
import React from "react";
import { Route } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import { Layout, Button, BackTop } from "antd";
import MessengerCustomerChat from 'react-messenger-customer-chat';
import Menu from "../../components/menu";
import Landing from "../../containers/landing";
import menuItems from "../../components/menu/menuItems";
import Blog from "../blog";
import FAQMD from "../../static/faq.md";
import Rent from "../rent";
import Laser from "../laser/";
import Gallery from "../gallery";

const { Header, Content, Footer } = Layout;

class Main extends React.PureComponent {
  // componentDidMount() {
  //   console.log(FB);
  // }

  render() {
    const { history } = this.props;
    return (
      <Layout>
        <MessengerCustomerChat 
          pageId="1217155661672701"
          appId="527071604748295"
          loggedInGreeting="Cześć! Jak Ci możemy pomóc?"
          loggedOutGreeting="Cześć! Jak Ci możemy pomóc?"
          themeColor="#2bb9cc"
          language="pl_PL"
        />
        <BackTop />
        <Header className="ls-header">
          <Menu history={history} />
        </Header>
        <Content className="ls-content">
          <Route exact path="/" component={Landing} />
          <Route exact path="/galeria" component={Gallery} />
          <Route
            exact
            path="/czeste-pytania"
            component={() => <Blog mdFile={FAQMD} title="Częste pytania" />}
          />
          <Route exact path="/o-depilacji/:id" component={Laser} />
          <Route exact path="/wynajem-lasera/:id" component={Rent} />
        </Content>
        <Footer className="ls-footer">
          <small className="copyright">
            © 2019 Laser Studio Będzin, all rights reserved
          </small>
          {menuItems.left.map(({ title, link, submenu }, i) => {
            return (
              !submenu && (
                <Button key={i} type="link" size="small">
                  <HashLink to={link}>{title}</HashLink>
                </Button>
              )
            );
          })}
        </Footer>
      </Layout>
    );
  }
}

export default Main;
