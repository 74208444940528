import "./Contact.less";
import React from "react";
import { Icon } from 'antd'

const Contact = () => {
  return (
    <div id="kontakt" className="ls-contact">
      <h1 className="text-primary">Kontakt</h1>

      <div className="contact-info">
        <div className="contact-item">
          <Icon type="home" />
          <div>
            Laser Studio Katarzyna Wójcik <br /> ul. Krośnieńska 1, 42-500
            Będzin <br /> w COCO Studio Urody Anety Zaręby
          </div>
        </div>
        <div className="contact-item">
          <Icon type="phone" />{" "}
          <div>
            <a href="tel:509 807 773">509 807 773</a>
            &nbsp;
            <b>lub</b>&nbsp;
            <a href="tel:880 502 204">880 502 204</a>
          </div>
        </div>
        <div className="contact-item">
          <Icon type="phone" />{" "}
          <div>
            <a href="tel:880 502 204">880 502 204</a>&nbsp;
            <b>(Wynajem lasera)</b>
          </div>
        </div>
        <div className="contact-item">
          <Icon type="mail" />  <a href="mailto:laser.studio.kw@gmail.com">laser.studio.kw@gmail.com</a>
        </div>
      </div>
      {/* <Divider />
      <Form>
        <Form.Item>
          <Input required placeholder="Adres Email"></Input>
        </Form.Item>
        <Form.Item>
          <Input required placeholder="Tytuł"></Input>
        </Form.Item>
        <Form.Item>
          <Input.TextArea
            required
            placeholder="Wiadomość"
            rows={4}
          ></Input.TextArea>
        </Form.Item>
        <Form.Item>
          <ReCAPTCHA sitekey="Your client site key" onChange={onCaptcha} />
        </Form.Item>
        <Button type="primary" disabled size="large">
          Wyślij
        </Button>
      </Form> */}
    </div>
  );
};

export default Contact;
