import "./Blog.less";
import React, { useEffect, useState } from "react";
import markdown from "marked";
import { Icon } from "antd";

const Blog = ({ title, bannerImage, mdFile }) => {
  const [content, setContent] = useState(null);

  useEffect(() => {
    async function getData(file) {
      const response = await fetch(file);
      const data = await response.text();
      setContent(data);
    }

    getData(mdFile);
  });

  if (content) {
    return (
      <div className="blog-wrapper">
        {bannerImage && <div className="banner-image" style={{ backgroundImage: `url(${bannerImage})` }}/>}
        <div className="ls-blog">
        {title && <h2 className="blog-title text-primary">{title}</h2>}
        <div
          className="blog-content"
          dangerouslySetInnerHTML={{ __html: markdown(content) }}
        />
      </div>
      </div>
    );
  }

  return <Icon type="loading" />;
};

export default Blog;
