import "./Pricing.less";
import React from "react";
import prices from "./prices";

const CURRENCY = "zł";

const Pricing = () => (
  <div id="cennik" className="ls-pricing">
    <h1 className="text-primary">Cennik</h1>
    <div className="pricing-lists-wrapper">
      {Object.keys(prices).map(key => {
        return (
          <ul key={key}>
            {prices[key].map(({ name, price }, i) => {
              return (
                <li key={i}>
                  <span>{name}</span>{" "}
                  <span>
                    {price} {CURRENCY}
                  </span>
                </li>
              );
            })}
          </ul>
        );
      })}
    </div>
  </div>
);

export default Pricing;
