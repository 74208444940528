import "./Laser.less";
import React from "react";
import { Route } from "react-router-dom";
import Blog from "../blog";

// md files
import AfterIntervention from "../../static/depilation/after-intervention.md";
import Contraindications from "../../static/depilation/contraindications.md";
import Effects from "../../static/depilation/effects.md";
import HowItWorks from "../../static/depilation/how-it-works.md";
import ForWho from "../../static/depilation/laser-depilation-for-who.md";
import Preparing from "../../static/depilation/preparing-before-intervention.md";
import ProblemWithHairs from "../../static/depilation/problem-with-hairs.md";
import LaserLightSheer from "../../static/depilation/laser-light-sheer-desire.md";
import Hair from "../../static/depilation/hair.md";

/*
0. find strategy to show subpages
1. show default page
2. add all routes here about laser
*/
const Laser = () => (
  <div className="ls-laser">
    <Route
      path="/o-depilacji/laser-light-sheer-desire"
      component={() => (
        <Blog
          title="Laser Light Sheer Desire"
          mdFile={LaserLightSheer}
          bannerImage="/images/blog/laser-light-sheer.jpg"
        />
      )}
    />
    <Route
      path="/o-depilacji/fazy-wzrostu-wlosa"
      component={() => (
        <Blog
          title="Fazy wzrostu włosa"
          mdFile={Hair}
          bannerImage="/images/blog/jak-przygotowac-sie.jpg"
        />
      )}
    />
    <Route
      path="/o-depilacji/istota-dzialania-lasera-do-depilacji"
      component={() => (
        <Blog
          title="Istota działania lasera do depilacji"
          mdFile={HowItWorks}
          bannerImage="/images/blog/istota-dzialania.jpg"
        />
      )}
    />
    <Route
      path="/o-depilacji/dla-kogo-depilacja-laserowa"
      component={() => (
        <Blog title="Dla kogo depilacja laserowa?" mdFile={ForWho} 
        bannerImage="/images/blog/dla-kogo.jpg"
        />
      )}
    />
    <Route
      path="/o-depilacji/problem-nadmiernego-owlosienia"
      component={() => (
        <Blog
          title="Problem nadmiernego owłosienia"
          mdFile={ProblemWithHairs}
          bannerImage="/images/blog/problem-nadmiernego.jpg"
        />
      )}
    />
    <Route
      path="/o-depilacji/budowa-wlosa"
      component={() => <Blog title="Budowa włosa" mdFile={Effects} 
      bannerImage="/images/blog/jak-przygotowac-sie.jpg"
      />}
    />
    <Route
      path="/o-depilacji/jak-przygotowac-sie-do-konsultacji-laserowej"
      component={() => (
        <Blog
          title="Jak przygotować się do konsultacji i próby laserowej?"
          mdFile={Preparing}
          bannerImage="/images/blog/jak-przygotowac-sie.jpg"
        />
      )}
    />
    <Route
      path="/o-depilacji/przeciwskazania"
      component={() => (
        <Blog title="Przeciwskazania" mdFile={Contraindications} 
        bannerImage="/images/blog/przeciw.jpg"
        />
      )}
    />
    <Route
      path="/o-depilacji/jak-postepowac-po-zabiegu"
      component={() => (
        <Blog title="Jak postępować po zabiegu?" mdFile={AfterIntervention} 
        bannerImage="/images/blog/jak-przygotowac-sie.jpg"
        />
      )}
    />
  </div>
);

export default Laser;
