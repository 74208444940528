import "./Rent.less";
import React from "react";
import { Route } from "react-router-dom";
import Blog from "../blog";

// md files
import RentingInYourPlace from "../../static/renting/renting-in-your-place.md";
import RentingLaser from "../../static/renting/renting-laser.md";
import WhyUs from "../../static/renting/why-us.md";

const Rent = () => (
  <div className="ls-rent">
    <Route
      path="/wynajem-lasera/wynajem-w-twoim-salonie"
      component={() => (
        <Blog
          title="Wynajem urządzenia do depilacji laserowej Light Sheer Desire w Twoim Salonie"
          mdFile={RentingInYourPlace}
          bannerImage="/images/blog/laser-light-sheer.jpg"
        />
      )}
    />
    <Route
      path="/wynajem-lasera/wynajem-urzadzenia-light-sheer-desire"
      component={() => (
        <Blog
          title="Wynajem urządzenia Light Sheer Desire"
          mdFile={RentingLaser}
          bannerImage="/images/blog/laser-light-sheer.jpg"
        />
      )}
    />
    <Route
      path="/wynajem-lasera/dlaczego-my"
      component={() => (
        <Blog
          title="Usługa depilacji laserowej to świetny pomysł na rozszerzenie oferty twojego salonu kosmetycznego"
          mdFile={WhyUs}
        />
      )}
    />
  </div>
);

export default Rent;
