import "./Menu.less";
import React, { useState } from "react";
import { HashLink } from "react-router-hash-link";
import { Menu as AntMenu, Icon, Drawer } from "antd";
import MenuItems from "./menuItems";

const { SubMenu: AntSubMenu } = AntMenu;

const Menu = () => {
  const [visible, setVisible] = useState(false);

  const navigateExternal = link => {
    window.open(link, "__blank");
  };

  const scrollToTop = () => {
    window.scrollTo(0, 0);
  }

  return (
    <React.Fragment>
      <AntMenu
        className="ls-left-menu"
        mode="horizontal"
        defaultSelectedKeys={["0"]}
        selectable={false}
      >
        {/* Left menu */}
        {/* TODO: scroll to top */}
        <AntMenu.Item key="logo" className="logo" onClick={scrollToTop}>
          <HashLink to="/">
            <img src="images/logo.png" alt="Laser Studio Będziń" />
          </HashLink>
        </AntMenu.Item>
        {MenuItems.left.map(({ title, link, submenu }, key) => {
          if (submenu) {
            return (
              <AntSubMenu
                key={key}
                title={
                  <span className="submenu-title-wrapper">
                    {title}
                    <Icon type="caret-down" />
                  </span>
                }
              >
                {submenu.map(
                  ({ title: submenuTitle, link: submenuLink }, submenuKey) => {
                    return (
                      <AntMenu.Item key={submenuKey}>
                        <HashLink to={submenuLink} href={submenuLink}>
                          {submenuTitle}
                        </HashLink>
                      </AntMenu.Item>
                    );
                  }
                )}
              </AntSubMenu>
            );
          }

          return (
            <AntMenu.Item key={key}>
              <HashLink to={link} href={link}>
                {title}
              </HashLink>
            </AntMenu.Item>
          );
        })}

        <AntMenu.Item
          className="ls-menu-toggle"
          onClick={() => setVisible(true)}
        >
          <Icon type="menu" />
        </AntMenu.Item>
      </AntMenu>
      <AntMenu className="ls-right-menu" mode="horizontal" selectable={false}>
        {MenuItems.right.map(({ icon, link }, key) => {
          return (
            <AntMenu.Item
              key={"icon" + key}
              onClick={() => navigateExternal(link)}
            >
              <Icon type={icon} />
            </AntMenu.Item>
          );
        })}
      </AntMenu>

      <Drawer
        className="ls-mobile-drawer"
        placement="right"
        closable={true}
        onClose={() => setVisible(false)}
        visible={visible}
      >
        <AntMenu
          mode="inline"
          className="ls-mobile-menu"
          defaultSelectedKeys={["0"]}
        >
          {MenuItems.left.map(({ title, link, submenu }, key) => {
            if (submenu) {
              return (
                <AntSubMenu title={title} key={key} className="mobile-submenu">
                  {submenu.map(
                    (
                      { title: submenuTitle, link: submenuLink },
                      submenuKey
                    ) => {
                      return (
                        <AntMenu.Item
                          key={submenuKey}
                          onClick={() => setVisible(false)}
                        >
                          <HashLink to={submenuLink}>{submenuTitle}</HashLink>
                        </AntMenu.Item>
                      );
                    }
                  )}
                </AntSubMenu>
              );
            }
            return (
              <AntMenu.Item key={key} onClick={() => setVisible(false)}>
                <HashLink to={link}>{title}</HashLink>
              </AntMenu.Item>
            );
          })}
          {MenuItems.right.map(({ icon, link }, key) => {
            return (
              <AntMenu.Item
                key={"icon" + key}
                onClick={() => {
                  navigateExternal(link);
                  setVisible(false);
                }}
              >
                <Icon type={icon} />
              </AntMenu.Item>
            );
          })}
        </AntMenu>
      </Drawer>
    </React.Fragment>
  );
};

export default Menu;
