import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router, Route } from "react-router-dom";
import { createBrowserHistory } from "history";
import "./styles/main.less";
import * as serviceWorker from "./serviceWorker";

import Main from "./containers/main";

const history = createBrowserHistory();

ReactDOM.render(
  <Router history={history}>
    <Route path="/" component={Main} />
  </Router>,
  document.getElementById("root")
);

serviceWorker.register();
