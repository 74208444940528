import "./Landing.less";
import React from "react";
import { HashLink } from "react-router-hash-link";
import { Row, Col, Carousel, Button, Divider } from "antd";
import Contact from "../../components/contact";
import Pricing from "../../components/pricing";
import Promotions from "../../components/promotions/";

const Landing = () => (
  <div className="ls-landing">
    <Carousel className="ls-hero">
      <div className="ls-carousel">
        <Row className="ls-hero-content" type="flex" justify="center">
          <Col>
            <div className="hero-img-container"></div>
          </Col>
          <Col>
            <div className="hero-description">
              <h1 className="text-primary">
                Najskuteczniejsza depilacja laserowa na świecie!
              </h1>
              <p>
                Jesteśmy gabinetem rekomendowanym przez firmę COHERENT– jedynego
                dystrybutora urządzenia LightSheer DESIRE w Polsce.
              </p>
              <Button type="primary" size="large" block>
                <HashLink to="/#o-nas">Więcej o nas</HashLink>
              </Button>
            </div>
          </Col>
        </Row>
      </div>
    </Carousel>
    <Divider />
    <Row
      className="ls-content-wrapper introduction"
      type="flex"
      justify="center"
      id="o-nas"
    >
      <Col className="introduction-column">
        <h1 className="text-primary">Poznaj Laser Studio</h1>
        <p>
          Laser Studio to gwarancja świadczenia najwyższej jakości usług. Sprzęt
          na którym pracujemy – LightSheer firmy Lumenis – jest jednym z
          najnowocześniejszych i najbardziej skutecznych na rynku.
          Wykwalifikowany personel, posiadający odpowiednie certyfikaty i
          ukończone szkolenia, zapewni Państwu maksymalny poziom bezpieczeństwa
          i zadowolenia z oferowanych usług.
        </p>
        <p>
          Nasz salon mieści się w Będzinie przy ulicy Krośnieńskiej 1 - osiedle
          Zamkowe - Budynek NZOZ Pro Femina w Studiu Urody Coco Anety Zaręby. W
          nowoczesnym i stylowym wnętrzu, każdy poczuje się wyjątkowo a nasz
          wykwalifikiwany personel zadba o najwyższy poziom usług.
        </p>
        <img src="/images/certificate.jpg"/>
      </Col>
    </Row>
    <Divider />
    <Row
      className="ls-content-wrapper features"
      type="flex"
      justify="space-between"
    >
      <Col className="feature">
        <h2>Na jakim sprzęcie pracujemy?</h2>
        <p>
          Laser diodowy LightSheer DESIRE® to innowacyjny, najnowszy produkt z
          rodziny laserów LightSheer amerykańskiej firmy Lumenis, uznawany za
          najmocniejszy i najskuteczniejszy...
        </p>
        <Button type="primary" block size="large">
          <HashLink to="/o-depilacji/laser-light-sheer-desire">Dowiedz się więcej</HashLink>
        </Button>
      </Col>
      <Col className="feature">
        <h2>Bezpieczeństwo</h2>
        <p>
          To co najważniejsze - czyli bezpieczeństwo przede wszystkim. Laser
          jest bezpieczny dla zdrowia - działa jedynie powierzchniowo na skórze.
          Nie wpływa na funkcjonowanie organizmu.
        </p>
        <Button type="primary" block size="large">
          <HashLink to="/o-depilacji/istota-dzialania-lasera-do-depilacji">
            Więcej o bezpieczeństwie
          </HashLink>
        </Button>
      </Col>
      <Col className="feature">
        <h2>Bez bólu i skuteczne</h2>
        <p>
          Depilacja laserowa jest obecnie najlepszą i najskuteczniejszą metodą w
          walce z nadmiernym owłosieniem ciała. Dzięki nowości firmy Lumenis
          przekonasz się, że może być też bezbolesna.
        </p>
        <Button type="primary" block size="large">
          <HashLink to="#cennik">Dowiedz się więcej</HashLink>
        </Button>
      </Col>
    </Row>
    <Divider />
    <Row className="ls-content-wrapper pricing-wrapper">
      <Pricing />
    </Row>
    <Divider />
    <Row className="ls-content-wrapper pricing-wrapper">
      <Promotions />
    </Row>
    <Divider />
    <Row className="ls-content-wrapper contact-wrapper" type="flex">
      <Contact />
    </Row>
  </div>
);

export default Landing;
